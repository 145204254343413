import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import * as React from 'react';
import { useTranslations } from 'next-intl';
import { cn } from 'mxcn';
import { InfoCircle } from 'react-bootstrap-icons';
import { HoverCard, HoverCardTrigger, HoverCardContent } from './hover-card';

function Label(props) {
  const common = useTranslations("Common");
  const optionalText = common("optionalField");
  const elementType = props.element ?? "label";
  const element = React.createElement(
    elementType,
    {
      ...props.labelProps,
      className: cn(
        "mb-1 dark:text-white flex items-center gap-1",
        props.hiddenLabel && "sr-only",
        props.labelClassnames
      )
    },
    /* @__PURE__ */ jsxs(Fragment, { children: [
      props.label,
      " ",
      props.isOptional ? /* @__PURE__ */ jsxs("span", { className: "text-sm italic", children: [
        "(",
        optionalText,
        ")"
      ] }) : null,
      props.description ? /* @__PURE__ */ jsx("span", { className: "ml-1", children: /* @__PURE__ */ jsxs(HoverCard, { children: [
        /* @__PURE__ */ jsx(HoverCardTrigger, { asChild: true, children: /* @__PURE__ */ jsx(InfoCircle, { width: 14, height: 14 }) }),
        /* @__PURE__ */ jsx(HoverCardContent, { pointerEvents: true, children: props.description })
      ] }) }) : null
    ] })
  );
  return element;
}

export { Label };
