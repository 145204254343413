export * from './socket-events';
export * from './routes';

var Cookie = /* @__PURE__ */ ((Cookie2) => {
  Cookie2["AccessToken"] = "snaily-cad-session";
  Cookie2["RefreshToken"] = "snaily-cad-refresh-token";
  return Cookie2;
})(Cookie || {});
const API_TOKEN_HEADER = "snaily-cad-api-token";
const USER_API_TOKEN_HEADER = "snaily-cad-user-api-token";
const allowedFileExtensions = [
  "image/png",
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/webp"
];
const IMAGES_REGEX = /https:\/\/(i.imgur.com|cdn.discordapp.com)\/.+/gi;

export { API_TOKEN_HEADER, Cookie, IMAGES_REGEX, USER_API_TOKEN_HEADER, allowedFileExtensions };
