import { jsx } from 'react/jsx-runtime';
import * as React from 'react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import { cn } from 'mxcn';

const HoverCardTrigger = HoverCardPrimitive.Trigger;
function HoverCard(props) {
  return /* @__PURE__ */ jsx(HoverCardPrimitive.Root, { closeDelay: 100, openDelay: 0, ...props });
}
const HoverCardContent = React.forwardRef(({ className, align = "center", sideOffset = 4, pointerEvents = false, ...props }, ref) => /* @__PURE__ */ jsx(
  HoverCardPrimitive.Content,
  {
    ref,
    align,
    sideOffset,
    className: cn(
      pointerEvents ? "pointer-events-auto" : "pointer-events-none",
      "bg-gray-200 dark:border dark:border-secondary dark:bg-tertiary shadow-lg w-full max-w-lg p-3 rounded-md dark:text-white hover-card animate-enter z-50",
      className
    ),
    ...props
  }
));
HoverCardContent.displayName = HoverCardPrimitive.Content.displayName;

export { HoverCard, HoverCardContent, HoverCardTrigger };
