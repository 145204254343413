import { jsxs, jsx } from 'react/jsx-runtime';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';

function ErrorMessage(props) {
  return /* @__PURE__ */ jsxs(
    "span",
    {
      ...props.errorMessageProps,
      className: "h-full flex items-start gap-1 mt-1 font-medium text-red-500",
      children: [
        /* @__PURE__ */ jsx(ExclamationTriangleFill, { className: "mt-1.5 mr-1", "aria-hidden": "true" }),
        props.errorMessage
      ]
    }
  );
}

export { ErrorMessage };
