import { jsx } from 'react/jsx-runtime';
import { useProgressBar } from '@react-aria/progress';
import { cn } from 'mxcn';

function Loader(props) {
  const { progressBarProps } = useProgressBar({ label: "loading..." });
  return /* @__PURE__ */ jsx(
    "div",
    {
      ...progressBarProps,
      style: { borderTopColor: "transparent" },
      className: cn(
        "w-5 h-5 border-2 border-gray-800 dark:border-gray-400  border-solid rounded-full animate-spin",
        props.className
      )
    }
  );
}

export { Loader };
