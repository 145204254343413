import { jsxs, jsx } from 'react/jsx-runtime';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { cva } from 'class-variance-authority';

const alertVariants = cva("flex flex-col p-2 px-4 text-black rounded-md shadow border", {
  variants: {
    type: {
      warning: "bg-orange-400 border-orange-500/80",
      error: "bg-red-400 border-red-500/80",
      success: "bg-green-400 border-green-500/80",
      info: "bg-slate-900 border-slate-500 text-white"
    }
  }
});
function Alert(props) {
  return /* @__PURE__ */ jsxs(
    "div",
    {
      role: "alert",
      className: alertVariants({
        className: props.className,
        type: props.type
      }),
      children: [
        props.title ? /* @__PURE__ */ jsxs("header", { className: "flex items-center gap-2 mb-2", children: [
          props.icon ?? /* @__PURE__ */ jsx(ExclamationCircleFill, {}),
          /* @__PURE__ */ jsx("h5", { className: "font-semibold text-lg", children: props.title })
        ] }) : null,
        props.message ? /* @__PURE__ */ jsxs("div", { className: "flex items-center gap-2", children: [
          !props.title ? props.icon ?? /* @__PURE__ */ jsx(ExclamationCircleFill, {}) : null,
          /* @__PURE__ */ jsx("p", { children: props.message })
        ] }) : null,
        props.children
      ]
    }
  );
}

export { Alert };
