import { jsxs, jsx } from 'react/jsx-runtime';
import * as React from 'react';
import { useTextField } from '@react-aria/textfield';
import { cn } from 'mxcn';
import { Input } from '../inputs/input';
import { Textarea } from '../inputs/textarea';
import { ErrorMessage } from '../error-message';
import { Label } from '../label';
import { PasswordInputButton } from '../inputs/password-input-button';

function TextField(props) {
  const _ref = React.useRef(null);
  const ref = props.inputRef ?? _ref;
  const { labelProps, inputProps, errorMessageProps } = useTextField(
    { ...props, inputElementType: props.isTextarea ? "textarea" : "input" },
    ref
  );
  return /* @__PURE__ */ jsxs("div", { className: cn("relative text-field flex flex-col mb-3", props.className), children: [
    /* @__PURE__ */ jsx(Label, { ...props, labelProps }),
    props.isTextarea ? /* @__PURE__ */ jsx(
      Textarea,
      {
        errorMessage: props.errorMessage,
        ref,
        ...inputProps
      }
    ) : /* @__PURE__ */ jsx(
      Input,
      {
        errorMessage: props.errorMessage,
        ref,
        ...inputProps
      }
    ),
    props.children,
    props.type === "password" && !props.isTextarea ? /* @__PURE__ */ jsx(PasswordInputButton, { inputRef: ref }) : null,
    props.errorMessage && /* @__PURE__ */ jsx(ErrorMessage, { errorMessage: props.errorMessage, errorMessageProps })
  ] });
}

export { TextField };
